import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Site Spotlight",
  "time": "stuff",
  "index": 3,
  "type": "project",
  "hidden": false,
  "splash": "Boosting productivity by highlighting search results you're interested in"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GitLink = makeShortcode("GitLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1><a parentName="h1" {...{
        "href": "https://github.com/DavidPeet8/Site-Spotlight"
      }}>{`Site Spotlight`}</a></h1>
    <p><strong parentName="p">{`Date Range:`}</strong>{` 2019`}</p>
    <p><strong parentName="p">{`Key Technologies:`}</strong>{` `}<inlineCode parentName="p">{`JQuery`}</inlineCode>{` `}<inlineCode parentName="p">{`JavaScript`}</inlineCode></p>
    <p><strong parentName="p">{`Github Source:`}</strong>{` `}<GitLink url="https://github.com/DavidPeet8/Site-Spotlight" mdxType="GitLink" /></p>
    <hr></hr>
    <p>{`Before the internet existed, researching was made difficult by the lack of access to information. Now we suffer from the opposite problem - there is too much information and we need to filter out the junk.`}</p>
    <p>{`Site spotlight was created to help do exactly this. Site spotlight highlights search results from favorite websites in different colors for easy visual identification.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      